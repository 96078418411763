import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import '../src/assets/rem.js'
import './index.css.css'
import './assets/iconfont.css'
import './assets/dot.js'

import $ from 'jquery'
Vue.prototype.$ = $

Vue.prototype.$http = axios;
axios.defaults.baseURL ='https://qiankyle.cn/learn'; 
axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
