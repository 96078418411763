import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Start',
    component:() => import('../views/Start.vue')
  },
  {
    path:'/index',
    name:'Index',
    component:() => import('../views/Main.vue')
  },
  {
    path:'/account',
    name:'Account',
    component:() => import('../components/User.vue'),
    redirect:'/account/usercenter',
    children:[
      {
        path:'/account/usercenter',
        name:'AccountCenter',
        component:() =>import('../components/UserSetting/AccountCenter.vue')
      },
      {
        path:'/account/activitygoal',
        name:'ActivityGoal',
        component:() => import('../components/UserSetting/ActivityGoal.vue')
      },
      {
        path:'/account/editingcenter',
        name:'EditingCenter',
        component:() => import('../components/UserSetting/EditingCenter.vue'),
        redirect:'/account/editingcenter/article',
        children:[
          {
            path:'/account/editingcenter/article',
            name:'Article',
            component:() => import('../components/UserSetting/UserArticlePages/Article.vue')
          },
          {
            path:'/account/editingcenter/queans',
            name:'QueAns',
            component:() => import('../components/UserSetting/UserArticlePages/QueAns.vue')
          }
        ]
      },
      {
        path:'/account/setting',
        name:'Setting',
        component:() => import('../components/UserSetting/Setting.vue')
      }
    ]
  },
  {
    path:'/activedetails/:id',
    name:'ActiveDetails',
    component:() =>import('../components/ActiveDetails.vue'),
    props:true
  },
  {
    path:'/zone',
    name:'Zone', 
    component:() => import('../components/Article/Zone.vue')
  },
  {
    path:'/md_article',
    name:'Md_Article', 
    component:() => import('../components/Article/Md_Article.vue')
  },
  {
    path:'/mp_blog',
    name:'ArticleManage',
    component:() => import('../components/Article/ArticleManage.vue')
  },
  {
    path:'/article/details/:id',
    name:'ArticleDetails',
    component:() => import('../components/Article/ArticleDetails.vue'),
    props:true
  },
  {
    path:'/myspace',
    name:'MySpace',
    component:() => import('../components/Space.vue'),
    redirect:'/myspace/article',
    children:[
      {
        path:'/myspace/article',
        name:'MySpaceArticle',
        component:() => import('../components/UserSetting/UserArticlePages/Article.vue')
      },
      {
        path:'/myspace/queans',
        name:'MySpaceQueAns',
        component:() => import('../components/UserSetting/UserArticlePages/QueAns.vue')
      }
    ]
  },
  {
    path:'/v_space/:id',
    name:'VSpace',
    component:() => import('../components/VSpace.vue'),
    props:true
  },
  {
    path:'/editor/blog/:id',
    name:'EditorArticle',
    component:() => import('../components/Article/RefreshBlog.vue'),
    props:true
  },
  {
    path:'/v_article',
    name:'VArticle',
    component:() => import('../components/UserSetting/UserArticlePages/V_Article.vue'),
    props:true
  },
  {
    path:'/events',
    name:'Event',
    component:() => import('../components/Event.vue')
  },
  {
    path:'/mainqa',
    name:'MainQA',
    component:() => import('../components/MainQA.vue')
  },
  {
    path:'/play',
    name:'Entertainment',
    component:() => import('../views/Tools/Entertainment.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next)=>{
  var isLogin = localStorage.getItem('token')
  if(to.path == '/'){
    if(isLogin){
      next('/index')
    }
  }else{
    if(isLogin){
      next()
    }else{
      next('/')
    }
  }
  next()
})
export default router
